<template>
    <b-card title="Heart Exchanges">
        <!-- search input -->
        <div class="custom-search d-flex justify-content-between">
            <!-- <b-form-group v-if="this.showAdd">
                <div class="d-flex align-items-center">
                    <b-button
                        id="toggle-btn"
                        v-b-modal.modal-prevent-closing
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        @click="addNew()"
                    >
                        <feather-icon
                            icon="PlusIcon"
                            class="mr-50"
                        />
                        <span class="align-middle">Add New</span>
                    </b-button>
                </div>
            </b-form-group> -->
            <b-form-group class="justify-content-end">
                <div class="d-flex align-items-center">
                    <label class="mr-1">Search</label>
                    <b-form-input
                        v-model="searchTerm"
                        placeholder="Search"
                        type="text"
                        class="d-inline-block"
                    />
                </div>
            </b-form-group>
        </div>
    
        <!-- table -->
        <vue-good-table
            :columns="columns"
            :rows="rows"
            :rtl="direction"
            :search-options="{
                enabled: true,
                externalQuery: searchTerm 
            }"
            :pagination-options="{
                enabled: true,
                perPage:pageLength
            }"
            styleClass="vgt-table striped condensed no-outline"
        >
    
            <template
                slot="table-row"
                slot-scope="props"
            >
    
                <!-- Column: Sender -->
                <span
                    v-if="props.column.field === 'sender'"
                    class="text-nowrap"
                >
                    <router-link :to="{ path: '/admin/user/profile/' + props.row.sender_profile.personal_info.profile_id }">
                        <span class="profile-container">
                            <span class="text-nowrap">{{ props.row.sender_profile.first_name }} {{ props.row.sender_profile.last_name }}</span>
                            <span class="text-nowrap">{{ props.row.sender_profile.personal_info.profile_id }}</span>
                        </span>
                    </router-link>
                </span>

                <!-- Column: Receiver -->
                <span
                    v-if="props.column.field === 'receiver'"
                    class="text-nowrap"
                >
                    <router-link :to="{ path: '/admin/user/profile/' + props.row.receiver_profile.personal_info.profile_id }">
                        <span class="profile-container">
                            <span class="text-nowrap">{{ props.row.receiver_profile.first_name }} {{ props.row.receiver_profile.last_name }}</span>
                            <span class="text-nowrap">{{ props.row.receiver_profile.personal_info.profile_id }}</span>
                        </span>
                    </router-link>
                </span>
                
                <!-- Column: Index -->
                <span v-else-if="props.column.field === 'index'">
                    <span>{{props.row.originalIndex+1}}</span>
                </span>

                <!-- Column: Timestamp -->
                <span v-else-if="props.column.field === 'timestamp'">
                    <span>{{ getTimeStamp(props.row.timestamp) }}</span>
                </span>
        
                <!-- Column: Common -->
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>
    
            <!-- pagination -->
            <template
                slot="pagination-bottom"
                slot-scope="props"
            >
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap ">
                            Showing 1 to
                        </span>
                        <b-form-select
                            v-model="pageLength"
                            :options="['3','5','10']"
                            class="mx-1"
                            @input="(value)=>props.perPageChanged({currentPerPage:value})"
                        />
                        <span class="text-nowrap"> of {{ props.total }} entries </span>
                    </div>
                    <div>
                        <b-pagination
                            :value="1"
                            :total-rows="props.total"
                            :per-page="pageLength"
                            first-number
                            last-number
                            align="right"
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mt-1 mb-0"
                            @input="(value)=>props.pageChanged({currentPage:value})"
                        >
                            <template #prev-text>
                                <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>
    </b-card>
</template>
  
  <script>
  
import {
BAvatar,BButton, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard,
BModal,VBModal,BCardText, BFormTextarea
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import vSelect from "vue-select"
import Ripple from "vue-ripple-directive";
import {mapState} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import moment from 'moment';

export default {
    components: {
        VueGoodTable,
        BAvatar,
        BCard,
        BBadge,
        BButton,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BModal,
        vSelect,
        BCardText,
        BFormTextarea
    },

    directives: {
        'b-modal': VBModal,
        Ripple,
    },

    data() {
        return {
            nameState: null,
            addNewMode:false,
            updateStatusMode: false,
            popupActive:true,
            status: {
                id: "",
                status: ""
            },
            modify:{
                id: null,
                name: null,
                district: null,
                country: null
            },
            country_id:null,
            pageLength: 5,
            dir: false,
            columns: [
                {
                    label: '#',
                    field: 'index',
                    type:'number',
                    tdClass: 'text-center ',
                    thClass:'text-center'
                },
                {
                    label: 'Sender',
                    field: 'sender',
                    tdClass: 'text-center',
                    thClass:'text-center'
                },
                {
                    label: 'Receiver',
                    field: 'receiver',
                    tdClass: 'text-center',
                    thClass:'text-center'
                },
                {
                    label: 'Timestamp',
                    field: 'timestamp',
                    tdClass: 'text-center',
                    thClass:'text-center'
                },
            ],
            showAdd:true,
            showEdit:false,
            showDelete:false,
            rows: [],
            searchTerm: '',
            statuses: [ 
                {
                    name: 'OTP Pending',
                    value: 'otp_pending'
                },
                {
                    name: 'OTP Verified',
                    value: 'otp_verified'
                },
                {
                    name: 'Review',
                    value: 'review'
                },
                {
                    name: 'Further Review',
                    value: 'further_review'
                },
                {
                    name: 'Further Correction Due',
                    value: 'further_correction_due'
                },
                {
                    name: 'Checked',
                    value: 'checked'
                },
                {
                    name: 'Edit',
                    value: 'edit'
                },
                {
                    name: 'Edit Review',
                    value: 'edit_review'
                },
                {
                    name: 'Active',
                    value: 'active'
                },
                {
                    name: 'Incompatible',
                    value: 'incompatible'
                },
                {
                    name: 'Deleted',
                    value: 'deleted'
                }
            ]
        }
    },

    computed: {
        ...mapState('hearts', ["users","userError","userLoading"]),
    
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },

    watch: {
        users(newValue, oldValue) {
            this.rows=this.users
        }
    },

    created() {
        this.$store.dispatch('hearts/fetchHeartExchanges')
        this.rows = this.users
        let permissions=JSON.parse(localStorage.getItem('permissions'))
        //   this.showAdd=permissions.includes("add_area")
        //   this.showEdit=permissions.includes("change_area")
        //   this.showDelete=permissions.includes("delete_area")
        if(this.showEdit||this.showDelete){
            this.columns.push({
                label: 'Action',
                field: 'action',
                tdClass: 'text-center',
                thClass:'text-center',
                sortable: false,
            })
        }
    },

    methods:{
        getTimeStamp(time) {
            return moment(time).format('MMMM Do YYYY, h:mm:ss a')
        },

        resetModal() {
            this.nameState = null
        },

        updatePremiumStatus(pid, status) { 
            this.$store.dispatch('users/updatePremiumStatus', {pid:pid, status:status}).then(result=>{
                if(result.code==200) {
                    this.$store.dispatch('users/fetchUsersByStatus', 'active')
                }
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: { 
                        title: result.code!=200?`Error`:`Success`,
                        icon: result.code!=200?`ThumbsDownIcon`:'ThumbsUpIcon',
                        variant: result.code!=200?`danger`:'success',
                        text: result.code!=200?`Couldn't update Status`:'Status Updated',
                    },
                })
            })
        }
    }
}
</script>
  
<style lang="scss" >
.no-outline{
    border:none !important;
}

.f20{
    font-size: 8px !important;
}

.vs__dropdown-menu {
  height: 200px;
}

.profile-container {
    display: flex;
    flex-direction: column
}

@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

</style>